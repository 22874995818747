import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () =>{
   
    const data = useStaticQuery(graphql`
    query FooterQuery{
        wp {
            generalSettings {
                title
              }
            acfOptionsFooter {
                footer {
                    bedrijfsnaam
                    contact {
                      email
                      telefoon
                    }
                    adres {
                      straat
                      postcode
                    }
                  }
            }
        }
        wpMenu(locations: {eq: GATSBY_FOOTER_MENU}) {
          menuItems {
            nodes {
              label
              uri
            }
          }
        }
    }
    `)

    return data
}