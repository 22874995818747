import { graphql, useStaticQuery } from "gatsby";
import { createGlobalStyle } from "styled-components"
import React from "react";
import Helmet from "react-helmet";

export default function GlobalStyles({rem}) {

  const data = useStaticQuery(graphql`
    query settingsQuery{
        wp {
        acfOptionsKleuren {
            kleuren {
            header {
                background
                textcolor
            }
            footer {
                background
                textcolor
            }
            textcolors {
                h1color
                h2color
                h3color
                textcolor
            }
            kleurpalet {
                accentcolor
                bgcolor
                primarycolor
                secondarycolor
                lightcolor
            }
            }
        }
        }
        }`)

  // Palet
  const primary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.primarycolor
  const secondary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.secondarycolor
  const accent = data.wp.acfOptionsKleuren.kleuren.kleurpalet.accentcolor
  const text = data.wp.acfOptionsKleuren.kleuren.textcolors.textcolor
  const background = data.wp.acfOptionsKleuren.kleuren.kleurpalet.bgcolor
  const light = data.wp.acfOptionsKleuren.kleuren.kleurpalet.lightcolor

  // Header
  const headerBackground = data.wp.acfOptionsKleuren.kleuren.header.background
  const headerText = data.wp.acfOptionsKleuren.kleuren.header.textcolor

  // Footer
  const footerBackground = data.wp.acfOptionsKleuren.kleuren.footer.background
  const footerText = data.wp.acfOptionsKleuren.kleuren.footer.textcolor

  // Headings
  const h1 = data.wp.acfOptionsKleuren.kleuren.textcolors.h1color
  const h2 = data.wp.acfOptionsKleuren.kleuren.textcolors.h2color
  const h3 = data.wp.acfOptionsKleuren.kleuren.textcolors.h3color

  const GlobalStyle = createGlobalStyle`

    :root {
      --primary-color: ${primary};
      --secondary-color: ${secondary};
      --accent-color: ${accent};
      --text-color: ${text};
      --bg-color: ${background};
      --light-color: ${light};
      --light-gray-color: #E9E9E9;
      --light-bg-color: #FDFBFF;
      --standard-font: ${rem};
    }
    body{
      font-family: "new-frank", sans-serif;
      font-style: normal;
    }
    h1{
      font-family: "new-frank", sans-serif;
      font-style: normal;
    }
    h2{
      font-family: "new-frank", sans-serif;
      font-style: normal;
    }
    h3{
      font-family: "new-frank", sans-serif;
      font-style: normal;
    }
    h4{
      font-family: "new-frank", sans-serif;
      font-style: normal;
    }
    .headerClass{
      background-color: ${headerBackground};
      color: ${headerText};
    }
    .footerClass{
      background-color: ${footerBackground};
      color: ${footerText};
    }
    .footerClass h1,  .footerClass h2,  .footerClass h3,  .footerClass h4{
      color: ${footerText};
    }
    .navigation{
      font-family: "new-frank", sans-serif;
      font-style: normal;
    }
  `

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/jsq8foy.css"></link>
      </Helmet>
    </>
  )
}