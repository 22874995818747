import React from "react";
import Container from "../../atoms/Container";
import './footer.css'
import { useFooter } from "../../../hooks/useFooter";
import { Link } from "gatsby";

export default function Footer(){

    const data = useFooter()
    const year = new Date().getFullYear()

    return(
        <footer>
            <div className="footer">
                <Container>
                    <div className="address">
                        <span>{data.wp.acfOptionsFooter.footer.bedrijfsnaam}</span>
                        <span>{data.wp.acfOptionsFooter.footer.adres.straat}</span>
                        <span>{data.wp.acfOptionsFooter.footer.adres.postcode}</span>
                    </div>
                    <div className="menu">
                        <span>&copy; {data.wp.generalSettings.title} {year}</span>
                        {data.wpMenu.menuItems.nodes.map((m, i)=>{
                            return <Link to={m.uri}>{m.label}</Link>
                        })}
                    </div>
                    <div className="contact">
                        <a href={`mailto:${data.wp.acfOptionsFooter.footer.contact.email}`}>E: {data.wp.acfOptionsFooter.footer.contact.email}</a>
                        <a href={`tel:${data.wp.acfOptionsFooter.footer.contact.telefoon}`}>T: {data.wp.acfOptionsFooter.footer.contact.telefoon}</a>
                    </div>
                </Container>
            </div>
        </footer>
    )
}