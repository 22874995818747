import React, {useState} from "react"
import Footer from "../../molecules/Footer"
import Header from "../../molecules/Header"
import GlobalStyles from "../GlobalStyles"
import PageContext from "../../../store/page-context"
import Accessibility from "../../atoms/Accessibility"
import { AnimatePresence } from "framer-motion"

const Layout = ({ children, location }) => {

  const [scale, setScale] = useState(1)
  const [animate, setAnimate] = useState(true)

  const scaleHandler = (e) => {
    setScale(e)
  }

  const animateHandler = (e) => {
    setAnimate(e)
  }

  return (
    <PageContext.Provider
      value={{
        scale: scale,
        change: scaleHandler,
        animate: animate,
        shouldAnimate: animateHandler
      }}
    >
      <GlobalStyles rem={scale} />
      <Header location={location} />
      <main>
        <AnimatePresence mode="wait">
          {children}
        </AnimatePresence>
      </main>
      <Footer />
      {/* <Accessibility /> */}
    </PageContext.Provider>
  )
}

export default Layout
