import { useStaticQuery, graphql } from "gatsby"

export const useHeaderSettings = () =>{
   
    const data = useStaticQuery(graphql`
    query HeaderSettingsQuery{
        wp {
            siteLogo {
              height
              width
              localFile {
                publicURL
              }
            }
        }
        wpMenu(locations: {eq: EXTRA_MENU}) {
          menuItems {
            nodes {
              parentId
              label
              uri
              target
              childItems {
                nodes {
                  uri
                  label
                  description
                  cssClasses
                }
              }
            }
          }
        }
    }
    `)

    return data
}