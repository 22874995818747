import { Link } from "gatsby"
import React , {useState, useEffect} from "react"
import Container from "../../atoms/Container"
import Navigation from "../../atoms/Navigation"
import { useHeaderSettings } from "../../../hooks/useHeaderSettings"
import './header.css';

const Header = ({location}) => {

  const arrow = <svg xmlns="http://www.w3.org/2000/svg" width="10.209" height="10.206" viewBox="0 0 10.209 10.206">    <path id="Path_122" data-name="Path 122" d="M.045,0V1.322H6.764L0,8.07.923,9,7.677,2.234V8.948H9V0Z" transform="translate(9.709 0.706) rotate(90)" fill="currentColor" stroke="currentColor" stroke-width="1"/></svg>

  const headerSettings = useHeaderSettings()
  const logo = headerSettings.wp.siteLogo

  const menu = headerSettings.wpMenu.menuItems.nodes

  const [menustate, setMenu] = useState(false)

  return (
    <header className={`header ${location.pathname.includes('/klanten/') && !location.pathname.endsWith('/klanten/') ? "klantpage" : ""}`}>
      <Container>
        <Link to="/" onClick={()=>{setMenu(false)}}><img className="hq-header__logo" width={`${logo.width}px`} height={`${logo.height}px`} src={logo.localFile.publicURL}/></Link>
        <Navigation menu={menustate} menuHandler={(m)=>{setMenu(m)}} />
        <div className="hq-secondary__nav">
          <nav>
            <ul>
              {menu.map((m, i)=>{
                if(!m.parentId){
                  return(
                    <li key={i} className="menu-item">
                      <Link target={m.target} to={m.uri} activeClassName="bold">{m.label}</Link>
                      {m.childItems.nodes.length !== 0 ? arrow : null}
                      {
                        m.childItems.nodes.length !== 0 ? 
                          <div className="sub-menu">
                            <ul>
                              {m.childItems.nodes?.map((c, i)=> <li><Link to={c.uri}>{c.label}</Link></li>)}
                            </ul>
                          </div>
                        : null
                      }
                    </li>
                  )
                }
              })}
            </ul>
          </nav>
        </div>
      </Container>
    </header>
  )
}

export default Header
