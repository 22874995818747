import { Link } from "gatsby";
import React, { useState } from "react";
import { useNav } from "../../../hooks/useNav";
import './navigation.css'
import { useHeaderSettings } from "../../../hooks/useHeaderSettings";

export default function Navigation({menu, menuHandler}){
  
    const data = useNav()
    const secondaryMenu = useHeaderSettings()

    return(
        <div className="header__mega--menu">
            <nav aria-labelledby="mainmenulabel" className={`${menu ? 'open' : ''}`}>
                <h2 id="mainmenulabel" className="hidden">Hoofdmenu</h2>
                <ul>
                    {data.wpMenu.menuItems.nodes.map((item, i)=>{
                        if(item.parentId == undefined){
                            return <li key={i} className={`${data.wpMenu.menuItems.nodes.length == (i + 1) ? '' : 'menu-item'} ${item.cssClasses.map((c) => {return `${c} `} )}`} onClick={()=>{menuHandler(false)}} ><Link to={item.uri} activeClassName={'text-accent'}>{item.label}</Link></li>
                        }
                    })}
                    
                    {secondaryMenu.wpMenu.menuItems.nodes.map((m, i)=>{
                        if(!m.parentId){
                            return(
                              <li key={i} className="menu-item secondary-menu" onClick={()=>{menuHandler(false)}}>
                                <Link target={m.target} to={m.uri} activeClassName="bold">{m.label}</Link>
                                {
                                  m.childItems.nodes.length !== 0 ? 
                                    <div className="sub-menu">
                                      <ul>
                                        {m.childItems.nodes?.map((c, i)=> <li><Link to={c.uri}>{c.label}</Link></li>)}
                                      </ul>
                                    </div>
                                  : null
                                }
                              </li>
                            )
                          }
                    })}

                </ul>
            </nav>
            <div className="menu__mobile--controls">
                <div className={`menu__toggle ${menu ? 'active' : ''}`} onClick={()=>{menuHandler(!menu)}}>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}